import { Col, Form, InputNumber, Radio, Row, Space, Typography } from 'antd';
import cn from 'classnames';
import { BlockType, ImageProxyResize, TestResultGradation } from 'pn-backend';
import { mergeRight, prop } from 'ramda';
import React, { FC, useState } from 'react';

import { RichEditor, Visible } from '~components';
import { DropType } from '~constants';
import { useIsMobile } from '~hooks';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { RichTextBlock } from '../../../components/RichEditor/types';
import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

export const TestResultBlock: FC<BlockProps<BlockType.TestResults>> = ({
  block,
  blockIndex,
  canDelete,
}) => {
  const { content, files } = block;

  const isMobile = useIsMobile();

  const [currentGradation, setCurrentGradation] = useState<TestResultGradation>(
    TestResultGradation.Minimum,
  );

  const [form] = Form.useForm();

  const formatValues: FormBlockProps<BlockType.TestResults>['formatValues'] = (formValues) => {
    const mergedContent = mergeRight(content, formValues);
    return {
      content: mergedContent,
      fileIds: [
        mergedContent.average.fileId,
        mergedContent.minimum.fileId,
        mergedContent.maximum.fileId,
      ].filter(isNotNil),
    };
  };

  return (
    <FormBlock
      form={form}
      block={block}
      blockIndex={blockIndex}
      initialValues={content}
      canDelete={canDelete}
      formatValues={formatValues}
    >
      <Row gutter={[0, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>Результат</Col>
            <Col>
              <Radio.Group>
                <Radio.Button
                  className={cn(
                    styles.type,
                    currentGradation === TestResultGradation.Minimum && styles.selected,
                  )}
                  onClick={() => {
                    setCurrentGradation(TestResultGradation.Minimum);
                  }}
                >
                  Минимальный
                </Radio.Button>
                <Radio.Button
                  className={cn(
                    styles.type,
                    currentGradation === TestResultGradation.Average && styles.selected,
                  )}
                  onClick={() => {
                    setCurrentGradation(TestResultGradation.Average);
                  }}
                >
                  Средний
                </Radio.Button>
                <Radio.Button
                  className={cn(
                    styles.type,
                    currentGradation === TestResultGradation.Maximum && styles.selected,
                  )}
                  onClick={() => {
                    setCurrentGradation(TestResultGradation.Maximum);
                  }}
                >
                  Максимальный
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {Object.values(TestResultGradation).map((gradation) => (
            <Visible key={gradation} isVisible={gradation === currentGradation}>
              <Row justify="center">
                <Col md={12} xs={24}>
                  <Row gutter={[30, 30]}>
                    <Col span={24}>
                      <Form.Item
                        name={[currentGradation, 'fileId']}
                        getValueFromEvent={prop('id')}
                        getValueProps={() => ({
                          value: getFileById(content[currentGradation].fileId, files),
                        })}
                      >
                        <DropZone
                          type={DropType.Image}
                          imageOptions={{
                            height: isMobile ? 177 : 240,
                            resize: ImageProxyResize.Fit,
                            width: isMobile ? 315 : 420,
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name={[currentGradation, 'caption']}>
                        <RichEditor
                          withColor={false}
                          fontSize={20}
                          lineHeight={24}
                          defaultData={content[currentGradation]?.caption}
                          bannedToolbarControls={[
                            RichTextBlock.bold,
                            RichTextBlock.italic,
                            RichTextBlock.highlight,
                            RichTextBlock.emoji,
                            RichTextBlock.strikethrough,
                            RichTextBlock.strikethrough,
                            RichTextBlock.underline,
                            RichTextBlock.tooltip,
                            RichTextBlock.link,
                          ]}
                          placeholder="Подпись"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name={[currentGradation, 'title']}>
                        <RichEditor
                          withColor={false}
                          fontSize={20}
                          lineHeight={24}
                          defaultData={content[currentGradation]?.title}
                          placeholder="Заголовок"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name={[currentGradation, 'text']}>
                        <RichEditor
                          withColor={false}
                          fontSize={20}
                          lineHeight={24}
                          defaultData={content[currentGradation]?.text}
                          placeholder="Текст"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Space size={24} wrap={false} align="center">
                        <Typography.Text strong style={{ fontSize: '18px' }}>
                          Интервал значений:
                        </Typography.Text>
                        <Form.Item name={[currentGradation, 'valuesFrom']}>
                          <InputNumber prefix="от" />
                        </Form.Item>
                        <Form.Item name={[currentGradation, 'valuesTo']}>
                          <InputNumber prefix="до" />
                        </Form.Item>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Visible>
          ))}
        </Col>
      </Row>
    </FormBlock>
  );
};
