import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Radio, Row } from 'antd';
import cn from 'classnames';
import { BlockType, ListType } from 'pn-backend';
import { isEmpty, not, pipe } from 'ramda';
import React, { FC, Fragment, useState } from 'react';

import { Delete, Dot } from '~assets';
import { RichEditor } from '~components';
import { BlockProps } from '~types';

import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

export const IncutBlock: FC<BlockProps<BlockType.Incut>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const { content } = block;

  const [lastAdded, setLastAdded] = useState(-1);
  const [showList, setShowList] = useState(content.items?.filter(pipe(isEmpty, not)).length > 0);
  const [form] = Form.useForm();
  const currentType = Form.useWatch('type', form);
  const currentListType = Form.useWatch('listType', form);

  const getListPrefix = (type: ListType, index = 0) => {
    switch (type) {
      case ListType.Dotted:
        return (
          <div className={styles.dot}>
            <Dot />
          </div>
        );
      case ListType.Numbered:
        return <div className={cn(styles.input, styles.withNumber)}>{index + 1}.</div>;
      default:
        return (
          <div className={styles.dot}>
            <Dot />
          </div>
        );
    }
  };

  const formatValues: FormBlockProps<BlockType.Incut>['formatValues'] = (changedContent) => ({
    content: { ...content, ...changedContent },
  });

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={content}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>Врезка</Col>
            <Col>
              <Form.Item name="type">
                <Radio.Group>
                  <Radio.Button
                    className={cn(styles.type, currentType === 'expander' && styles.selected)}
                    value="expander"
                  >
                    Раскрывашка
                  </Radio.Button>
                  <Radio.Button
                    className={cn(styles.type, currentType === 'text' && styles.selected)}
                    value="text"
                  >
                    Врезка текста
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item name="caption" className={styles.inputRow}>
            <TextArea
              autoSize
              rows={1}
              className={styles.input}
              bordered={false}
              placeholder="Заголовок"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="content">
            <RichEditor
              withColor={false}
              defaultData={content.content}
              fontSize={20}
              lineHeight={24}
              placeholder="Напишите текст"
              title="Текст"
              noFieldUnderline
            />
          </Form.Item>
        </Col>
        {!showList && (
          <Col span={24}>
            <Button
              onClick={() => {
                setShowList(true);
              }}
              type="text"
              className={styles.addButtonWrapper}
            >
              <Row gutter={[10, 10]}>
                <Col>
                  <PlusOutlined className={styles.addButton} />
                </Col>
                <Col>Добавить список</Col>
              </Row>
            </Button>
          </Col>
        )}
        {showList && (
          <>
            <Col span={24}>
              <Row className={styles.title} justify="space-between">
                <Col>Список</Col>
                <Col>
                  <Form.Item name="listType">
                    <Radio.Group>
                      <Radio.Button
                        className={cn(
                          styles.type,
                          currentListType === ListType.Numbered && styles.selected,
                        )}
                        value={ListType.Numbered}
                      >
                        Цифровой
                      </Radio.Button>
                      <Radio.Button
                        className={cn(
                          styles.type,
                          currentListType === ListType.Dotted && styles.selected,
                        )}
                        value={ListType.Dotted}
                      >
                        Символьный
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="space-between" gutter={[30, 30]}>
                <Col span={24}>
                  <Form.List name="items">
                    {(fields, { add, remove }) => (
                      <Row gutter={[20, 20]}>
                        <Col span={24}>
                          <Row gutter={[20, 20]} align="bottom" justify="space-between">
                            {fields.map((item, index) => {
                              const onKeyPress = (event: any) => {
                                if (event.charCode === 13) {
                                  add('', index + 1);
                                  setLastAdded(index + 1);
                                  return true;
                                }
                                return true;
                              };

                              return (
                                <Fragment key={item.key}>
                                  <Col md={23} xs={20}>
                                    <Row gutter={[5, 5]}>
                                      <Col md={1} xs={2}>
                                        {getListPrefix(currentListType, index)}
                                      </Col>
                                      <Col md={23} xs={22}>
                                        <Form.Item name={[index]}>
                                          <TextArea
                                            autoFocus={index === lastAdded}
                                            autoSize
                                            rows={1}
                                            className={styles.input}
                                            bordered={false}
                                            placeholder="Пункт"
                                            onKeyPress={onKeyPress}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Button
                                    icon={
                                      <Delete
                                        onClick={() => {
                                          remove(index);
                                        }}
                                      />
                                    }
                                    type="text"
                                  />
                                </Fragment>
                              );
                            })}
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Button
                            onClick={() => {
                              add('');
                              setLastAdded(fields.length);
                            }}
                            type="text"
                            className={styles.addButtonWrapper}
                          >
                            <Row gutter={[10, 10]}>
                              <Col>
                                <PlusOutlined className={styles.addButton} />
                              </Col>
                              <Col>Добавить пункт</Col>
                            </Row>
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </FormBlock>
  );
};
