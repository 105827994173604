import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Radio, Row, Space } from 'antd';
import cn from 'classnames';
import {
  BlockType,
  EMPTY_RAW_DRAFT_CONTENT_STATE,
  ImageProxyResize,
  TestAnswerType,
} from 'pn-backend';
import { compose, dec, equals, inc, not, prop } from 'ramda';
import React, { FC, useState } from 'react';

import { RichEditor, Visible } from '~components';
import { DropType } from '~constants';
import { useIsMobile } from '~hooks';
import { BlockContent, BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { RichTextBlock } from '../../../components/RichEditor/types';
import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

export const TestQuestionBlock: FC<BlockProps<BlockType.TestQuestion>> = ({
  block,
  blockIndex,
  canDelete,
}) => {
  const { content, files } = block;

  const isMobile = useIsMobile();

  const [lastAdded, setLastAdded] = useState(-1);

  const [form] = Form.useForm();
  const { getFieldValue } = form;

  const answerType =
    Form.useWatch<BlockContent<BlockType.TestQuestion>['answerType']>('answerType', form) ??
    content.answerType;
  const answerOptions =
    Form.useWatch<BlockContent<BlockType.TestQuestion>['answerOptions']>('answerOptions', form) ??
    content.answerOptions;

  const formatValues: FormBlockProps<BlockType.TestQuestion>['formatValues'] = (
    changedContent,
  ) => ({
    content: { ...content, ...changedContent },
    fileIds: [changedContent.fileId, ...changedContent.answerOptions.map(prop('fileId'))].filter(
      isNotNil,
    ),
  });

  const setRightAnswer = (rightIndex: number) => {
    answerOptions
      .filter(compose(not, equals(rightIndex), (_, index) => index))
      .forEach((_, index) => {
        form.setFieldValue(['answerOptions', index, 'isRightAnswer'], false);
      });
  };

  return (
    <FormBlock
      form={form}
      block={block}
      blockIndex={blockIndex}
      initialValues={content}
      canDelete={canDelete}
      formatValues={formatValues}
      bordered
    >
      <Row gutter={[0, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>{`Вопрос ${inc(blockIndex)}`}</Col>
            <Col>
              <Form.Item name="hideRightAnswer" valuePropName="checked" noStyle>
                <Checkbox>Не показывать правильный ответ</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Col md={12} xs={24}>
              <Row gutter={[30, 30]}>
                <Col span={24}>
                  <Row className={styles.title}>
                    <Col>Картинка к вопросу</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="fileId"
                    getValueFromEvent={prop('id')}
                    getValueProps={() => ({
                      value: getFileById(content.fileId, files),
                    })}
                  >
                    <DropZone
                      type={DropType.Image}
                      imageOptions={{
                        height: isMobile ? 177 : 240,
                        resize: ImageProxyResize.Fit,
                        width: isMobile ? 315 : 420,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="coverCaption">
                    <RichEditor
                      withColor={false}
                      fontSize={20}
                      lineHeight={24}
                      defaultData={content.coverCaption}
                      bannedToolbarControls={[
                        RichTextBlock.bold,
                        RichTextBlock.italic,
                        RichTextBlock.highlight,
                        RichTextBlock.emoji,
                        RichTextBlock.strikethrough,
                        RichTextBlock.strikethrough,
                        RichTextBlock.underline,
                        RichTextBlock.tooltip,
                        RichTextBlock.link,
                      ]}
                      placeholder="Подпись"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row className={styles.title}>
                    <Col>Вопрос</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item name="question">
                    <RichEditor
                      withColor={false}
                      fontSize={20}
                      lineHeight={24}
                      defaultData={content.question}
                      placeholder="Напишите вопрос"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row className={styles.title} justify="space-between">
                    <Col>Ответы</Col>
                    <Col>
                      <Form.Item name="answerType">
                        <Radio.Group>
                          <Radio.Button
                            className={cn(
                              styles.type,
                              answerType === TestAnswerType.Text && styles.selected,
                            )}
                            value={TestAnswerType.Text}
                          >
                            Текст
                          </Radio.Button>
                          <Radio.Button
                            className={cn(
                              styles.type,
                              answerType === TestAnswerType.Image && styles.selected,
                            )}
                            value={TestAnswerType.Image}
                          >
                            Изображение
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.List name="answerOptions">
                    {(fields, { add, move, remove }) => (
                      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        {fields.map(({ key, name }, index) => {
                          const optionValue = getFieldValue(['answerOptions', index, 'text']);

                          const isFirst = index === 0;
                          const isLast = index === fields.length - 1;
                          const isOnly = fields.length === 1;

                          const onKeyPress = () => {
                            add(
                              {
                                fileId: null,
                                isRightAnswer: false,
                                text: EMPTY_RAW_DRAFT_CONTENT_STATE,
                              },
                              index + 1,
                            );
                            setLastAdded(index + 1);
                            return true;
                          };

                          return (
                            <Space
                              key={key}
                              size={16}
                              direction="vertical"
                              style={{ display: 'flex' }}
                            >
                              <Visible isVisible={answerType === TestAnswerType.Image}>
                                <Form.Item
                                  name={[name, 'fileId']}
                                  getValueFromEvent={prop('id')}
                                  getValueProps={() => ({
                                    value: getFileById(answerOptions[name]?.fileId, files),
                                  })}
                                >
                                  <DropZone
                                    type={DropType.Image}
                                    imageOptions={{
                                      height: isMobile ? 177 : 240,
                                      resize: ImageProxyResize.Fit,
                                      width: isMobile ? 315 : 420,
                                    }}
                                  />
                                </Form.Item>
                              </Visible>
                              <Row gutter={16} align="middle" wrap={false}>
                                <Col>
                                  <Space size={0}>
                                    <Button
                                      type="text"
                                      disabled={isFirst || isOnly}
                                      icon={<ArrowUpOutlined className={styles.icon} />}
                                      onClick={() => {
                                        move(index, dec(index));
                                      }}
                                    />
                                    <Button
                                      type="text"
                                      disabled={isLast || isOnly}
                                      icon={<ArrowDownOutlined className={styles.icon} />}
                                      onClick={() => {
                                        move(index, inc(index));
                                      }}
                                    />
                                  </Space>
                                </Col>
                                <Col flex="auto" className={styles.option}>
                                  <Row align="middle">
                                    <Col span={24}>
                                      <Form.Item name={[name, 'text']}>
                                        <RichEditor
                                          needFocus={index === lastAdded}
                                          externalIndex={index}
                                          withColor={false}
                                          fontSize={20}
                                          lineHeight={24}
                                          noFieldUnderline
                                          defaultData={optionValue}
                                          placeholder="Ответ"
                                          prefixSpan={[2, 4]}
                                          textPrefix={
                                            <Form.Item
                                              name={[name, 'isRightAnswer']}
                                              valuePropName="checked"
                                              noStyle
                                            >
                                              <Radio
                                                onClick={() => {
                                                  setRightAnswer(name);
                                                }}
                                              />
                                            </Form.Item>
                                          }
                                          onEnterPress={onKeyPress}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Button
                                    type="text"
                                    icon={<DeleteOutlined className={styles.icon} />}
                                    onClick={() => {
                                      remove(name);
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Space>
                          );
                        })}
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={() => {
                            add({
                              fileId: null,
                              isRightAnswer: false,
                              text: EMPTY_RAW_DRAFT_CONTENT_STATE,
                            });
                          }}
                        >
                          Добавить ответ
                        </Button>
                      </Space>
                    )}
                  </Form.List>
                </Col>
                <Col span={24}>
                  <Form.Item name="rightAnswerCaption">
                    <RichEditor
                      withColor={false}
                      fontSize={20}
                      lineHeight={24}
                      defaultData={content.rightAnswerCaption}
                      placeholder="Подпись к правильному ответу"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="wrongAnswerCaption">
                    <RichEditor
                      withColor={false}
                      fontSize={20}
                      lineHeight={24}
                      inlineStylesToRetain={[]}
                      defaultData={content.wrongAnswerCaption}
                      placeholder="Подпись к неправильному ответу"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormBlock>
  );
};
